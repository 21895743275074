<template>
  <div>
    <b-container class="py-md-3 py-2 navBar">
      <b-row class="justify-content-between px-2">
        <img :src="logo" width="80px" />
        <div>
          <img :src="trust_img" width="80px" class="mt-2" />
          <br />
          <small class="pl-1 fs--14"
            >Trustscore <b>4.4</b> | <b>3058</b> reviews
          </small>
        </div>
      </b-row>
    </b-container>
    <div :class="page == `first` ? `headerSection` : `bannerSecond`">
      <FirstBanner @nextPage="next_page" v-if="page == `first`" />
      <SecondBanner v-else-if="page == `second`" />
      <ThirdBanner v-else-if="page == `third`" />
    </div>
    <BottomSection
      v-if="page == `first` || page == `third`"
      :buttonText="
        page == `first` ? `Check jouw inbraakrisico` : `Vul je gegevens in`
      "
      :link="page == `first` ? `zipInput` : `form`"
    />
    <FooterSection />
  </div>
</template>

<script>
import logo from "@/assets/postcoode/logo.png";
import trust_img from "@/assets/postcoode/trust.png";
// components
import FirstBanner from "../components/Postcodechecker/FirstBanner.vue";
import SecondBanner from "../components/Postcodechecker/SecondBanner.vue";
import ThirdBanner from "../components/Postcodechecker/ThirdBanner.vue";
import BottomSection from "../components/Postcodechecker/BottomSection.vue";
import FooterSection from "../components/Postcodechecker/Footer.vue";
export default {
  name: "Postcodechecker",
  components: {
    FirstBanner,
    SecondBanner,
    ThirdBanner,
    BottomSection,
    FooterSection,
  },
  data() {
    return {
      page: `first`,
      logo,
      trust_img,
    };
  },
  methods: {
    next_page() {
      this.page = "second";
    },
  },
  watch: {
    page() {
      if (this.page == "second") {
        setTimeout(() => {
          this.page = "third";
        }, 2500);
      }
    },
  },
  created() {
    document.title = "Verisure";
  },
};
</script>

<style lang="scss" scoped>
.navBar {
  font-family: "Fira Sans", sans-serif;
  max-width: 1040px !important;
}
.headerSection {
  font-family: "Fira Sans", sans-serif;
  background: url(../assets/postcoode/background.png);
  background-size: cover;
  background-position-x: 70%;
  @media only screen and (max-width: 767.98px) {
    background-size: cover;
    background-position-x: left;
  }
}
.bannerSecond {
  font-family: "Fira Sans", sans-serif;
  background-color: #ff0032 !important;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1040px !important;
  }
}
</style>
