<template>
  <b-container
    class="bedankt d-flex flex-column align-items-center h-100 justify-content-center"
  >
    <div>
      <p class="m-0 bedankt__heading">Bedankt voor uw interesse,</p>
      <p class="m-0 bedankt__heading bedankt__heading-red">
        wij laten u z.s.m. meer weten.
      </p>
    </div>

    <div class="d-flex justify-content-center my-2">
      <img class="img-fluid" src="../../assets/postcoode/bottoomStepsImg.png" />
    </div>

    <span class="bedankt__text mt-2">
      *Eenmalige korting op de montage- en aansluitkosten bij het afnemen van
      <br />
      de Verisure alarmdiensten. Kosteloze installatie van de videodeurbel,<br />
      maandelijkse kosten gelden. Niet geldig in combinatie met andere<br />
      aanbiedingen. Vraag naar de actievoorwaarden.
    </span>

    <img
      v-if="profileId"
      referrerpolicy="no-referrer-when-downgrade"
      :src="`https://republish.verbeterthuis.nl/m/5945/0cd46dbf922d/?event=6929&unique_conversion_id=${profileId}`"
      style="width: 1px; height: 1px; border: 0px"
    />
  </b-container>
</template>

<script>
export default {
  name: "Bedankt",
  props: {
    profileId: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.bedankt {
  font-family: "Fira Sans", sans-serif;
  background: none !important;
  &__heading {
    font-size: 30px;
    line-height: 38px;
    color: #000000 !important;
    font-weight: 800 !important;
    text-align: center !important;
  }
  &__heading-red {
    color: #ff0033 !important;
  }
  &__text {
    font-size: 11px;
    color: #424242;
    text-align: center;
  }

  @media (max-width: 1023.99px) {
    &__heading {
      font-size: 23px;
      line-height: 30px;
    }
  }
  @media (max-width: 767.99px) {
    &__heading {
      font-size: 20px;
      line-height: 25px;
    }
  }
  @media (max-width: 575.99px) {
    &__heading {
      font-size: 18px;
      line-height: 20px;
    }
  }
}
</style>
