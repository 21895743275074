<template>
  <div class="postcodeBanner text-white pb-1" id="zipInput">
    <b-container>
      <b-row class="pt-4 pt-md-5">
        <b-col cols="12" md="8" lg="7">
          <h5 class="postcodeBanner__smallHeader text-capitlize fs--26">
            Heeft jouw huishouden een laag, middal of hoog inbraakrisico?
          </h5>
          <h1 class="fs--md--45 fw--700">
            Controleer het<br />
            inbraakrisico<br />
            van jouw huis.
          </h1>
          <p>
            <img :src="arrow" class="postcodeBanner__arrowImg" />

            Vul je postcode in en bekijk het risico in jouw woonwijk.
          </p>
          <div class="bg-white py-1 pl-2 postcodeBanner__postcodeDiv">
            <b-form @submit.prevent="onSubmit" class="row">
              <b-form-input
                class="postcodeBanner__zipInput ml-3 px-0 my-auto text-center"
                v-model="zip"
                :state="zipValidation"
                placeholder="2000AB"
                maxLength="6"
                required
              ></b-form-input>
              <b-button
                pill
                block
                type="submit"
                variant="primary"
                class="py-3 position-relative primary--btn d-flex align-items-center postcodeBanner__zipInput--btn"
              >
                Check inbraakrisico
                <img :src="btn_arrow" width="25px"
              /></b-button>
            </b-form>
          </div>
          <small class="text-warning ml-4" v-if="isInValid" >Postcode niet correct</small>

          <p class="fs--18 py-3 py-md-5 font-weight-bold">
            Verisure geeft <u>40%* korting</u> op de <br />
            montage- en aansluitkosten aan iedereen <br />
            om hun huis te beveiligen.
          </p>
        </b-col>
        <b-col cols="12" md="4" lg="5" class="rightSection"
          ><img :src="banner_bell"
        /></b-col>
      </b-row>
      <div class="text-center">
        <img :src="arrowImg" class="bottomArrow" />
      </div>
    </b-container>
  </div>
</template>

<script>
import arrow from "@/assets/postcoode/header_arrow2.png";
import banner_bell from "@/assets/postcoode/40_discount22.png";
import btn_arrow from "@/assets/postcoode/btn_rightArrow.png";
import arrowImg from "@/assets/postcoode/arrow.png";
import { mapActions } from "vuex";

export default {
  name: "FirstBanner",
  data() {
    return {
      zip: "",
      arrow,
      banner_bell,
      btn_arrow,
      arrowImg,
      isInValid:false,
      validationCheck: true,
    };
  },
  methods: {
    ...mapActions(["setPostcode"]),

    onSubmit() {
      if (this.validationCheck) {
        this.$emit("nextPage");
        this.setPostcode(this.zip);
      }else{
          this.isInValid=true
      }
    },
  },
  computed: {
    zipValidation() {
    this.isInValid=false
      if (this.zip == "") {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4}[a-zA-Z]{2})$/;
      if (this.zip.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1040px !important;
  }
}
.postcodeBanner {
  font-family: "Fira Sans", sans-serif;
  height: 620px;

  @media only screen and (max-width: 767.98px) {
    height: auto;
  }
  &__smallHeader {
    color: #ebff0d;
    text-transform: uppercase;
    font-weight: bold;
  }
  &__arrowImg {
    margin-top: -90px;
    margin-bottom: -95px;
    margin-left: -90px;
    width: 70px;
    margin-right: 20px;
    @media (max-width: 1099.98px) {
      display: none;
    }
  }
  &__postcodeDiv {
    border-radius: 50px;
    width: 65%;
    @media (min-width: 768px) and (max-width: 992.98px) {
      width: 75%;
    }
    .form-control:focus {
      color: #495057;
      background-color: #fff;
      border-color: rgba(128, 189, 255, 0) !important;
      outline: 0;
      box-shadow: none !important;
    }
    @media only screen and (max-width: 767.98px) {
      width: 100%;
    }
  }
  &__zipInput {
    border: none;
    width: 27%;
    font-size: 16px;
    @media (min-width: 450px) and(max-width: 767.98px) {
      width: 30%;
    }
    // &::placeholder,
    // &::-webkit-input-placeholder,
    // &:-moz-placeholder,
    // &::-moz-placeholder,
    // &:-ms-input-placeholder {
    //   font-size: 14px;
    // }
    &--btn {
      width: 63%;
      font-size: 15px;
      justify-content: end;
      font-weight: bold;
      @media (max-width: 767.98px) {
        justify-content: center;
      }
      // @media (max-width: 449.98px) {
      //   justify-content: center;
      // }
      img {
        margin-left: 10px;

        @media (min-width: 450px) and(max-width: 767.98px) {
          position: absolute;
          right: 20px;
        }
        @media (max-width: 349.98px) {
          margin-left: 5px;
        }
      }
    }
  }
  & .rightSection {
    display: flex;
    align-items: end;
    margin-left: -80px;
    @media only screen and (max-width: 767.98px) {
      display: block;
      text-align: center;
      margin-left: 0px;
    }
    & img {
      width: 250px;
      @media only screen and (max-width: 767.98px) {
        margin-top: -20px;
        width: 180px;
      }
    }
  }
}
.bottomArrow {
  margin-top: -24px;
}
</style>
