<template>
  <b-container
    id="form"
    class="postcodeForm rounded py-2 bg-white shadow d-flex flex-column justify-content-center text-center"
  >
    <div v-if="!submited">
      <p class="fw--700 fs--18 my-3 padding_md">
        Vul hieronder je gegevens in
        <br class="d-xl-block d-lg-none d-block" />
        en claim jouw gratis videodeurbel en <br />
        40% korting bij Verisure.
      </p>
      <div class="d-flex justify-content-center">
        <b-form @submit.prevent="onSubmit" class="w-75">
          
  <b-form-checkbox v-model="isChecked" :style="{ display: 'none'  }">
  </b-form-checkbox>
 
 
          <b-input-group class="my-2">
            <template #prepend>
              <b-input-group-text
                ><img class="pl-2" :src="person_icon"
              /></b-input-group-text>
            </template>
            <b-form-input
              class="py-4"
              v-model="form.firstname"
              :state="FnameValidationCheck"
              placeholder="Voornaam"
              required
            ></b-form-input>
          </b-input-group>
          <div class="d-flex text-left ml-3"><small class="text-danger" v-if="firstNameValid" >Voornaam niet correct</small></div>

          <b-input-group class="my-2">
            <template #prepend>
              <b-input-group-text
                ><img class="pl-2" :src="person_icon"
              /></b-input-group-text>
            </template>
            <b-form-input
              class="py-4"
              v-model="form.lastname"
              :state="LnameValidationCheck"
              placeholder="Achternaam"
              required
            ></b-form-input>
          </b-input-group>
          <div class="d-flex text-left ml-3"><small class="text-danger" v-if="lastNameValid" >Achternaam niet correct</small></div>

          <b-input-group class="my-2">
            <template #prepend>
              <b-input-group-text
                ><img class="pl-2" :src="mail_icon"
              /></b-input-group-text>
            </template>
            <b-form-input
              class="py-4"
              type="text"
              v-model="form.email"
              :state="emailValidationCheck"
              placeholder="E-mail adres"
              required
            ></b-form-input>
          </b-input-group>
          <div class="d-flex text-left ml-3"><small class="text-danger" v-if="emailValid" >E-mailadres niet correct</small></div>

          <b-input-group class="my-2">
            <template #prepend>
              <b-input-group-text
                ><img class="pl-2" :src="phone_icon"
              /></b-input-group-text>
            </template>
            <b-form-input
              class="py-4"
              v-model="form.phone_number"
              maxLength="10"
              placeholder="Telefoonnummer"
              :state="phoneValidationCheck"
              required
            ></b-form-input>
          </b-input-group>
          <div class="d-flex text-left ml-3 mb-2"><small class="text-danger" v-if="phoneValid" >Telefoonnummer niet correct</small></div>

          <b-button
            type="submit"
            pill
            variant="primary"
            block
            class="primaryred--btn px-4 py-2 position-relative mx-auto d-flex align-items-center justify-content-center postcodeBanner__zipInput--btn"
            ><b-spinner v-if="postcodeCheckerLoader" class="text-center" />
            <span v-else> Bevestigen </span>
            <img
              v-if="!postcodeCheckerLoader"
              class="ml-2"
              :src="btnArrow_icon"
              width="22px"
            />
          </b-button>
          <p class="text-muted my-1">100% kosteloos en vrijblijvend.</p>
          <p class="fs--10">
            Door op 'Bevestigen' te drukken ga je akkoord met de algemene
            voorwaarden. Verisure informeert je per e-mail en/of telefoon gratis
            over de mogelijkheden.
          </p>
        </b-form>
      </div>
    </div>
    <div v-else class="py-5">
      <h5 class="text-secondry pb-2 pb-md-3 fw--700">
        <span>{{ postcodeCheckerName }},</span> bedankt voor je deelname en
        aanvraag.
      </h5>
      <p>
        Een van onze beveiligingsexperts zal in de komende dagen telefonisch
        contact met je opnemen om het inbraak risico en je wensen door te nemen.
      </p>
      <img src="../../assets/logo1.png" alt="Logo" width="30%" class="pt-2" />
      <p class="pt-2 font-weight-bold">
        Geef inbrekers geen kans met Verisure.
      </p>
      <img
        v-if="profile != null"
        referrerpolicy="no-referrer-when-downgrade"
        :src="`https://republish.verbeterthuis.nl/m/5584/971f3eb663fb/?event=6529&unique_conversion_id=${profile}`"
        style="width: 1px; height: 1px; border: 0px"
      />
      
    </div>
  </b-container>
</template>

<script>
import person_icon from "@/assets/postcoode/person_icon.png";
import mail_icon from "@/assets/postcoode/mail_icon.png";
import phone_icon from "@/assets/postcoode/phone_icon.png";
import btnArrow_icon from "@/assets/postcoode/btn_rightArrow.png";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "PostCodeForm",
  data() {
    return {
      submited: false,
      profile: "",
      person_icon,
      mail_icon,
      phone_icon,
      isChecked:false,
      btnArrow_icon,
      validationCheck: true,
      firstNameValid:false,
      lastNameValid:false,
      emailValid:false,
      phoneValid:false,
      form: {
        language: "nl_NL",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        site_custom_url:
          "https://verisure.verbeterthuis.nl/VerisureWoonveiligheid365",
        site_custom_name: "af_republish_verisure_woonveiligheid",
        answers: [3332],
      },
    };
  },
  methods: {
    ...mapActions(["postcodeCheckerLead"]),

    async onSubmit() {
  
      this.firstNameValid = !this.FnameValidationCheck;
      this.lastNameValid = !this.LnameValidationCheck;
      this.emailValid = !this.emailValidationCheck;
      this.phoneValid = !this.phoneValidationCheck;

      if (!this.firstNameValid && !this.lastNameValid && !this.emailValid && !this.phoneValid && !this.isChecked) {
        // console.log({ ...this.form, zip: this.getPostcode });
        let resp = await this.postcodeCheckerLead({
          ...this.form,
          zip: this.getPostcode,
          site_subid: this.$route.query.oa_id,
          publisher_id: this.$route.query.site,
        });

        if (resp.success == true) {
          (this.submited = true), (this.profile = resp.profileid);
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "postcodeCheckerLoader",
      "getPostcode",
      "postcodeCheckerName",
      "postcodeCheckerId",
    ]),

    FnameValidationCheck() {
      this.firstNameValid=false

      if (this.form.firstname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },

    LnameValidationCheck() {
      this.lastNameValid=false

      if (this.form.lastname == "") {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidationCheck() {
      this.phoneValid=false

      if (this.form.phone_number == "") {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^0\d{9}$/;
      if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidationCheck() {
      this.emailValid=false
      if (this.form.email == "") {
        this.validationCheck = false;
        return null;
      }

      if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.postcodeForm {
  @media (min-width: 830px) and (max-width: 899.98px) {
    .padding_md {
      padding: 0 20px !important;
    }
  }
  .postcodeBanner__zipInput--btn {
    font-weight: bold;

    img {
      position: absolute;
      right: 10px;
    }
  }
  & .input-group-text {
    border-radius: 30px 0px 0px 30px !important;
  }
  & .form-control {
    border-left: none !important;
    background: #e9ecef !important;
    border-radius: 0px 30px 30px 0px !important;
    clip-path: inset(-5px -5px -5px 0px) !important;

    &:focus {
      border-left: none !important;
      clip-path: inset(-5px -5px -5px 0px) !important;
    }
  }
}
</style>
