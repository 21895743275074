<template>
  <b-container class="form d-flex flex-column align-items-center">
    <p class="m-0 form__heading form__heading-red">Gefeliciteerd!</p>
    <p class="m-0 form__heading">
      Uw woning komt in aanmerking om het allernieuwste <br />
      alarmsysteem uit te proberen.
    </p>
    <div
      id="form"
      class="postcodeForm rounded py-2 mt-md-5 mt-4 bg-white shadow d-flex flex-column justify-content-center text-center"
    >
      <p class="m-0 py-3 form__title">
        Hoe kunnen we u bereiken <br />
        voor meer informatie?
      </p>
      <div class="d-flex justify-content-center">
        <b-form @submit.prevent="onSubmit" class="form__main">
          <input type="checkbox" class="hidden-checkbox" v-model="bootCheck" />
          <b-input-group class="my-2">
            <template #prepend>
              <b-input-group-text
                ><img class="pl-2" :src="person_icon"
              /></b-input-group-text>
            </template>
            <b-form-input
              class="py-4"
              v-model="form.firstname"
              @input="handleFieldInput('firstname')"
              :state="FnameValidationCheck"
              placeholder="Voornaam"
              required
            ></b-form-input>
          </b-input-group>
          <div class="d-flex text-left ml-3">
            <small class="text-danger" v-if="FnameValidationCheck === false"
              >Voornaam niet correct</small
            >
          </div>
          <b-input-group class="my-2">
            <template #prepend>
              <b-input-group-text
                ><img class="pl-2" :src="person_icon"
              /></b-input-group-text>
            </template>
            <b-form-input
              class="py-4"
              v-model="form.lastname"
              :state="LnameValidationCheck"
              @input="handleFieldInput('lastname')"
              placeholder="Achternaam"
              required
            ></b-form-input>
          </b-input-group>
          <div class="d-flex text-left ml-3">
            <small class="text-danger" v-if="LnameValidationCheck === false"
              >Achternaam niet correct</small
            >
          </div>
          <b-input-group class="my-2">
            <template #prepend>
              <b-input-group-text
                ><img class="pl-2" :src="mail_icon"
              /></b-input-group-text>
            </template>
            <b-form-input
              class="py-4"
              type="text"
              v-model="form.email"
              @input="handleFieldInput('email')"
              :state="emailValidationCheck"
              placeholder="E-mail adres"
              required
            ></b-form-input>
          </b-input-group>
          <div class="d-flex text-left ml-3">
            <small class="text-danger" v-if="emailValidationCheck === false"
              >E-mailadres niet correct</small
            >
          </div>
          <b-input-group class="my-2">
            <template #prepend>
              <b-input-group-text
                ><img class="pl-2" :src="phone_icon"
              /></b-input-group-text>
            </template>
            <b-form-input
              class="py-4"
              v-model="form.phone_number"
              @input="handleFieldInput('phone_number')"
              maxLength="10"
              placeholder="Telefoonnummer"
              :state="phoneValidationCheck"
              required
            ></b-form-input>
          </b-input-group>
          <div class="d-flex text-left ml-3">
            <small class="text-danger" v-if="phoneValidationCheck === false"
              >Telefoonnummer niet correct</small
            >
          </div>

          <b-button
            class="form__btn px-md-4 px-3 py-2 mt-3 d-flex align-items-center justify-content-center"
            size="md"
            type="submit"
          >
            <b-spinner v-if="postcodeCheckerLoader" class="text-center" />
            <span v-else>Meer informatie!</span>
            <img
              src="../../assets/verisurehouse/btn-arrow.png"
              v-if="!postcodeCheckerLoader"
              class="ml-3"
            />
          </b-button>

          <p class="form__description mb-2 mt-3">Geheel vrijblijvend</p>
          <p class="form__sub-description m-0 pb-3">
            Door op “Meer informatie" te klikken stemt u in met onze privacy
            verklaring. Verisure informeert u per e-mail en/of telefoon gratis
            over de alarmdiensten.
          </p>

          <img
            src="../../assets/verisurehouse/arrow-down.png"
            class="d-md-block d-none arrow"
          />
        </b-form>
      </div>
    </div>
  </b-container>
</template>

<script>
import person_icon from "@/assets/postcoode/person_icon.png";
import mail_icon from "@/assets/postcoode/mail_icon.png";
import phone_icon from "@/assets/postcoode/phone_icon.png";
import btnArrow_icon from "@/assets/postcoode/btn_rightArrow.png";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "PostCodeForm",
  data() {
    return {
      submited: false,
      profile: "",
      person_icon,
      mail_icon,
      phone_icon,
      btnArrow_icon,
      bootCheck: false,
      // firstNameValid: false,
      // lastNameValid: false,
      // emailValid: false,
      // phoneValid: false,

      validationCheck: true,
      form: {
        language: "nl_NL",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        site_custom_url: "https://verisure.verbeterhuis/verisurehuishoudens",
        site_custom_name: "af_republish_verisure_huishoudensgezocht",
      },
    };
  },
  methods: {
    ...mapActions(["postcodeCheckerLead"]),
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    async onSubmit() {
      // this.firstNameValid = !this.FnameValidationCheck;
      // this.lastNameValid = !this.LnameValidationCheck;
      // this.emailValid = !this.emailValidationCheck;
      // this.phoneValid = !this.phoneValidationCheck;

      if (
        this.validationCheck
        // !this.firstNameValid &&
        // !this.lastNameValid &&
        // !this.emailValid &&
        // !this.phoneValid &&
        // !this.isChecked
      ) {
        // console.log("payload:",{ ...this.form, zip: this.getPostcode });
        let resp = await this.postcodeCheckerLead({
          ...this.form,
          bootCheck: this.bootCheck,
          zip: this.getPostcode,
          site_subid: this.$route.query.clickid,
          publisher_id: this.$route.query.site,
          answers: ["3332"],
        });

        if (resp.success == true) {
          this.submited = true;
          this.profile = resp.profileid;
          this.$emit("handleNextClick", resp?.profileid);
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "postcodeCheckerLoader",
      "getPostcode",
      "postcodeCheckerName",
      "postcodeCheckerId",
      "getErrorResponses",
    ]),

    FnameValidationCheck() {
      if (this.form.firstname == "" && !this.getErrorResponses.firstname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;
      if (this.getErrorResponses.firstname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },

    LnameValidationCheck() {
      if (this.form.lastname == "" && !this.getErrorResponses.lastname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^([a-zA-Z ]){1,30}$/;

      if (this.getErrorResponses.lastname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidationCheck() {
      if (
        this.form.phone_number == "" &&
        !this.getErrorResponses.phone_number
      ) {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^0\d{9}$/;
      if (this.getErrorResponses.phone_number == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidationCheck() {
      if (this.form.email == "" && !this.getErrorResponses.email) {
        this.validationCheck = false;
        return null;
      }
      if (this.getErrorResponses.email == false) {
        this.validationCheck = false;
        return false;
      } else if (
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden-checkbox {
  opacity: 0;
}
.postcodeForm {
  font-family: "Fira Sans", sans-serif;
  max-width: fit-content;
  width: 42% !important;
  position: relative;
  @media (min-width: 830px) and (max-width: 899.98px) {
    .padding_md {
      padding: 0 20px !important;
    }
  }
  .postcodeBanner__zipInput--btn {
    font-weight: bold;
  }
  & .input-group-text {
    border-radius: 30px 0px 0px 30px !important;
    border: none !important;
  }
  & .form-control {
    border: none !important;
    border-left: none !important;
    background: #e9ecef !important;
    border-radius: 0px 30px 30px 0px !important;
    clip-path: inset(-5px -5px -5px 0px) !important;

    &:focus {
      border-left: none !important;
      clip-path: inset(-5px -5px -5px 0px) !important;
    }
    &::placeholder {
      font-size: 16px;
      letter-spacing: 0px;
      line-height: 38px;
      color: #959595;
      font-weight: 400;
    }
  }

  .arrow {
    top: -34px;
    right: -55px;
    position: absolute;
  }

  @media (max-width: 1023.99px) {
    width: 50% !important;
  }

  @media (max-width: 767.99px) {
    width: 100% !important;
  }
}

.form {
  font-family: "Fira Sans", sans-serif;

  &__heading {
    font-size: 30px;
    color: #000000;
    font-weight: 800;
    text-align: center;
    line-height: 40px;
  }
  &__heading-red {
    color: #ff0033 !important;
  }
  &__main {
    width: 75% !important;
  }

  &__title {
    font-size: 20px;
    letter-spacing: -1px;
    line-height: 25px;
    color: #333333;
    font-weight: 700;

    text-align: center;
  }

  &__description {
    font-size: 16px;
    color: #999999;
    font-weight: 400;
    text-align: center;
  }
  &__sub-description {
    font-size: 11px;
    color: #424242;
    font-weight: 400;

    text-align: center;
  }

  &__btn {
    border-radius: 27px;
    font-size: 16px;
    background-color: #32b006;
    border: none !important;
    text-transform: uppercase;

    letter-spacing: 1px;
    color: #ffffff;
    font-weight: 600;
    min-width: 100%;
    position: relative;

    img {
      position: absolute;
      right: 20px;
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 1023.99px) {
    &__btn {
      font-size: 14px;
    }

    &__heading {
      font-size: 23px;
      line-height: 30px;
    }

    &__main {
      width: 75% !important;
    }

    &__title {
      font-size: 18px;
      line-height: 20px;
    }

    &__description {
      font-size: 15px;
    }
    &__sub-description {
      font-size: 11px;
    }
  }
  @media (max-width: 767.99px) {
    &__btn {
      font-size: 13px;
    }

    &__heading {
      font-size: 20px;
      line-height: 25px;
    }

    &__title {
      font-size: 16px;
      line-height: 20px;
    }

    &__description {
      font-size: 14px;
    }
    &__sub-description {
      font-size: 10px;
    }
  }
  @media (max-width: 575.99px) {
    &__btn {
      font-size: 10px;
    }

    &__heading {
      font-size: 18px;
      line-height: 20px;
    }
  }
  .btn {
    &:hover,
    &:focus,
    &:active {
      background-color: #32b006 !important;
      border: none !important;
      box-shadow: none !important;
    }
  }
}
</style>
