<template>
  <b-container fluid="lg" class="thirdBanner pt-md-5 pt-3">
    <b-row>
      <b-col cols="12" md="7" class="text-">
        <p class="fs--md--50 fw--700 heading">
          <span class="primary--text">PAS OP! </span>Uw huis kan extra
          veiligheid gebruiken.
        </p>
        <div class="d-flex">
          <p>
            Daarom geeft Verisure jou
            <b>40% korting + een gratis* videodeurbel</b> om jouw huis beter te
            beveiligen.
            <span class="d-md-block d-none"
              >Vul hiernaast je gegevens in en claim je korting en gratis
              videodeurbel.</span
            >
          </p>
          <img :src="arrow" class="arrow" />
        </div>
        <img :src="img" class="formSectionImg" />
      </b-col>
      <b-col cols="12" md="5"> <FormSection /> </b-col>
    </b-row>
    <!-- <div class="text-center">
      <img :src="arrowImg" class="bottomArrow" />
    </div> -->
  </b-container>
</template>

<script>
import arrow from "@/assets/postcoode/form_arrow.png";
import img from "@/assets/postcoode/formSection_img2.png";
import arrowImg from "@/assets/postcoode/arrow.png";
import FormSection from "./Form.vue";

export default {
  name: "ThirdBanner",
  components: {
    FormSection,
  },
  data() {
    return {
      arrow,
      img,
      arrowImg,
    };
  },
};
</script>

<style lang="scss" scoped>
.thirdBanner {
  height: 580px;
  @media only screen and (max-width: 767.98px) {
    height: auto;
  }
  & .heading {
    line-height: 1;
  }
  & .arrow {
    height: fit-content;
    margin-left: auto;
    @media only screen and (max-width: 767.98px) {
      display: none;
    }
  }
  & .formSectionImg {
    margin-top: -20px;
    width: 620px;
    position: relative;
    z-index: 9;
    @media (max-width: 1199.98px) {
      width: 600px;
    }
    @media (max-width: 991.98px) {
      width: 550px;
    }
    @media (max-width: 849.98px) {
      width: 500px;
    }
    @media only screen and (max-width: 767.98px) {
      width: 100%;
    }
  }
}
.text--yellow {
  color: #ebff0d;
}
.bottomArrow {
  margin-top: -24px;
  @media only screen and (max-width: 767.98px) {
    margin-top: 0px;
    margin: 15px;
  }
}
</style>
