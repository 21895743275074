<template>
  <b-container class="py-md-3 py-2 navBar">
    <b-row class="justify-content-between px-2">
      <img :src="logo" width="80px" />
      <div>
        <div class="d-flex mt-2 align-items-center">
          <img :src="trust_img" width="80px" class="mr-2" />
          <img :src="stars" width="auto" />
        </div>
        <!-- <br /> -->
        <small class="pl-1 fs--14"
          >Trustscore <b>4.4</b> | <b>3058</b> reviews
        </small>
      </div>
    </b-row>
  </b-container>
</template>

<script>
import logo from "../../assets/postcoode/logo.png";
import trust_img from "../../assets/postcoode/trust.png";
import stars from "../../assets/postcoode/stars.png";
export default {
  name: "Header",

  created() {
    document.title = "Verisure Huishoudens";
  },
  data() {
    return {
      logo,
      trust_img,
      stars,
    };
  },
};
</script>

<style lang="scss" scoped>
.navBar {
  font-family: "Fira Sans", sans-serif;
  max-width: 1040px !important;
}
</style>
