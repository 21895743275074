<template>
  <b-container class="alarm-section">
    <div v-if="!currentQuestion" class="d-flex justify-content-center">
      <img class="img-fluid" :src="alarmImage" />
    </div>

    <b-row class="py-2">
      <b-col
        v-for="item in alarmItems"
        :key="item.text"
        xs="12"
        md="6"
        class="mt-4"
      >
        <AlarmItem :item="item" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import alarmImage from "../../assets/postcoode/bottoomStepsImg.png";
import watch from "../../assets/verisurehouse/watch.png";
import user from "../../assets/verisurehouse/user.png";
import phone from "../../assets/verisurehouse/phone.png";
import tool from "../../assets/verisurehouse/tool.png";
import wifi from "../../assets/verisurehouse/wifi.png";
import levens from "../../assets/verisurehouse/levens.png";
import AlarmItem from "./AlarmItem.vue";

export default {
  name: "VerisureAlarm",
  props: {
    currentQuestion: {
      type: Number,
      default: "",
    },
  },
  data() {
    return {
      alarmImage,
      alarmItems: [
        { text: "Reactie binnen 29 seconden", icon: watch },
        { text: "Unieke en versnelde melding aan politie", icon: user },
        { text: "Alarmcentrale 24/7", icon: tool },
        { text: "SOS-knop voor noodgevallen", icon: wifi },
        { text: "Altijd controle vanaf de mobiele telefoon", icon: phone },
        { text: "Levenslange garantieservice", icon: levens },
      ],
    };
  },
  components: {
    AlarmItem,
  },

  created() {
    document.title = "Verisure Huishoudens";
  },
};
</script>

<style lang="scss" scoped>
.alarm-section {
  font-family: "Fira Sans", sans-serif;
  max-width: 1040px !important;
  padding-top: 30px;
  //   padding-top: 300px;
  padding-bottom: 30px;
}
</style>
