<template>
  <!-- <div @mouseenter="updateUsers" @mouseleave="removeActiveUser"> -->
  <div>
    <b-container class="py-md-3 py-2 navBar">
      <b-row class="justify-content-between px-2">
        <img :src="logo" width="80px" />
        <div>
          <div class="d-flex mt-2 align-items-center">
            <img :src="trust_img" width="80px" class="mr-2" />
            <img :src="stars" width="auto" />
          </div>
          <!-- <br /> -->
          <small class="pl-1 fs--14"
            >Trustscore <b>4.4</b> | <b>3058</b> reviews
          </small>
        </div>
      </b-row>
    </b-container>
    <div :class="page == `first` ? `headerSection` : `bannerSecond`">
      <FirstBanner @nextPage="next_page" v-if="page == `first`" />
      <SecondBanner v-else-if="page == `second`" />
      <ThirdBanner v-else-if="page == `third`" />
    </div>
    <BottomSection
      v-if="page == `first` || page == `third`"
      :buttonText="
        page == `first` ? `Check jouw inbraakrisico` : `Vul je gegevens in`
      "
      :link="page == `first` ? `zipInput` : `form`"
    />
    <FooterSection />
    <!--  -->
    <!-- <button @click="setActiveUser">+</button>
    <button @click="removeActiveUser">-</button>
    {{ this.activeUsers.activeUsers }} -->
  </div>
</template>

<script>
import logo from "@/assets/postcoode/logo.png";
import trust_img from "@/assets/postcoode/trust.png";
import stars from "@/assets/postcoode/stars.png";

// components
import FirstBanner from "../components/PostcodecheckerV3/FirstBanner.vue";
import SecondBanner from "../components/PostcodecheckerV3/SecondBanner.vue";
import ThirdBanner from "../components/PostcodecheckerV3/ThirdBanner.vue";
import BottomSection from "../components/PostcodecheckerV3/BottomSection.vue";
import FooterSection from "../components/PostcodecheckerV3/Footer.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "PostcodecheckerV3",
  components: {
    FirstBanner,
    SecondBanner,
    ThirdBanner,
    BottomSection,
    FooterSection,
  },
  data() {
    return {
      page: `first`,
      logo,
      trust_img,
      stars,
    };
  },
  methods: {
    ...mapActions(["getActiveUsers", "setActiveUser", "removeActiveUser"]),
    next_page() {
      this.page = "second";
    },
    async updateUsers() {
      await this.getActiveUsers();
      await this.setActiveUser();
    },
    handler(e) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = "";
      this.removeActiveUser();
      return (e.returnValue = "Are you sure you want to exit?");
    },
    removeUser() {
      alert("off");
      // this.removeActiveUser();
    },
  },
  watch: {
    page() {
      if (this.page == "second") {
        setTimeout(() => {
          this.page = "third";
        }, 2500);
      }
    },
  },
  computed: {
    ...mapGetters(["activeUsers"]),
  },
  created() {
    document.title = "Verisure";
  },
  // mounted() {
  //   // window.addEventListener("beforeunload", this.removeUser);

  //   window.addEventListener("beforeunload", function (e) {
  //     this.removeActiveUser();
  //     console.log(1212);
  //     var confirmationMessage = "";
  //     e.returnValue = confirmationMessage;
  //     return confirmationMessage;
  //   });
  //   this.updateUsers();
  // },
  // beforeDestroy() {
  //   // window.removeEventListener("beforeunload", this.removeUser);
  // },
};
</script>

<style lang="scss" scoped>
.navBar {
  font-family: "Fira Sans", sans-serif;
  max-width: 1040px !important;
}
.headerSection {
  font-family: "Fira Sans", sans-serif;
  background: url(../assets/postcoode/backgroundv3.png);
  background-size: cover;
  background-position-x: 70%;
  @media only screen and (max-width: 767.98px) {
    background-size: cover;
    background-position-x: left;
  }
}
.bannerSecond {
  font-family: "Fira Sans", sans-serif;
  background-color: #f2f2f2 !important;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1040px !important;
  }
}
</style>
