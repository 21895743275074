<template>
  <div class="postcodeBanner pb-1" id="zipInput">
    <b-container fluid="lg">
      <b-row class="pt-4 pt-md-5 mx-md-0 mx-sm-4 mx-0">
        <b-col cols="12" md="8" lg="7">
          <h5 class="postcodeBanner__smallHeader text- fs--20">
            Laag, middel of hoog inbraakrisico
          </h5>
          <h1 class="fs--sm--45 fw--700">
            Hoe hoog is het<br />
            <u>inbraakrisico</u><br />
            van jouw huis?
          </h1>
          <p class="position-relative">
            Vul je postcode in en bekijk het risico in jouw woonwijk.
            <img :src="arrow" class="postcodeBanner__arrowImg" />
          </p>
          <div class="bg-white py-1 pl-2 postcodeBanner__postcodeDiv">
            <b-form @submit.prevent="onSubmit" class="row">
              <b-form-input
                class="postcodeBanner__zipInput ml-3 px-0 my-auto text-center"
                v-model="zip"
                :state="zipValidation"
                placeholder="2000AB"
                maxLength="6"
                required
              ></b-form-input>
              <b-button
                pill
                block
                type="submit"
                variant="primary"
                class="py-3 position-relative primaryred--btn d-flex align-items-center postcodeBanner__zipInput--btn"
              >
                Check inbraakrisico
                <img :src="btn_arrow" width="25px"
              /></b-button>
            </b-form>
          </div>
          <small class="text-danger" v-if="isInValid" >Postcode niet correct</small>

          <div class="d-flex align-items-center justify-content-center w-65">
            <small class="font-weight-bold fs--15"
              ><span class="primary--text">
                {{ activeUsers.activeUsers }} anderen
              </span>
              doen op dit moment de inbraakcheck.
            </small>
          </div>
          <p class="fs--18 pt-2 pt-md-4 mb-2 font-weight-bold">
            Verisure geeft
            <u class="primary--text"> 40%* korting</u> op de montage-<br />
            en aansluitkosten aan iedereen om hun huis te beveiligen.
          </p>
          <span class="fs--16 font-weight-bold"
            >Deze aanbieding is geldig t/m

            <span class="primary--text">
              {{ moment().add(7, "days").format("D MMMM") }}
            </span></span
          >
        </b-col>
        <b-col cols="12" md="4" lg="5" class="rightSection"
          ><img :src="banner_bell"
        /></b-col>
      </b-row>
      <div class="text-center">
        <img :src="arrowImg" class="bottomArrow" />
      </div>
    </b-container>
  </div>
</template>

<script>
import arrow from "@/assets/postcoode/header_arrow.png";
// import banner_bell from '@/assets/postcoode/banner_bellv3.png';
import banner_bell from "../../assets/postcoode/40_discount2.png";
import btn_arrow from "@/assets/postcoode/btn_rightArrow.png";
import arrowImg from "@/assets/postcoode/arrowB.png";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FirstBanner",
  data() {
    return {
      zip: "",
      arrow,
      banner_bell,
      btn_arrow,
      arrowImg,
      isInValid:false,
      validationCheck: true,
    };
  },
  methods: {
    ...mapActions(["setPostcode", "setActiveUser", "getActiveUsers"]),

    onSubmit() {
      if (this.validationCheck) {
        this.$emit("nextPage");
        this.setPostcode(this.zip);
      }  else{
          this.isInValid=true
      }
    },
  },
  mounted() {
    this.setActiveUser();
    this.getActiveUsers();
  },
  computed: {
    ...mapGetters(["activeUsers"]),
    zipValidation() {
      this.isInValid=false

      if (this.zip == "") {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4}[a-zA-Z]{2})$/;
      if (this.zip.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1040px !important;
  }
}
.postcodeBanner {
  font-family: "Fira Sans", sans-serif;
  height: 580px;

  @media only screen and (max-width: 767.98px) {
    height: auto;
  }
  &__smallHeader {
    color: #ff0032;
    // text-transform: uppercase;
    font-weight: bold;
  }
  &__arrowImg {
    position: absolute;
    width: 70px;
    top: -50px;
    @media (max-width: 991.98px) {
      display: none;
    }
  }
  &__postcodeDiv {
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.1);
    border-radius: 50px;
    width: 65%;
    @media (min-width: 768px) and (max-width: 992.98px) {
      width: 75%;
    }
    .form-control:focus {
      color: #495057;
      background-color: #fff;
      border-color: rgba(128, 189, 255, 0) !important;
      outline: 0;
      box-shadow: none !important;
    }
    @media only screen and (max-width: 767.98px) {
      width: 100%;
    }
  }
  &__zipInput {
    border: none;
    width: 27%;
    font-size: 16px;
    @media (min-width: 640px) and (max-width: 767.98px) {
      width: 31.5%;
    }
    @media (min-width: 500px) and(max-width: 639.98px) {
      width: 29%;
    }
    // &::placeholder,
    // &::-webkit-input-placeholder,
    // &:-moz-placeholder,
    // &::-moz-placeholder,
    // &:-ms-input-placeholder {
    //   font-size: 14px;
    // }
    &--btn {
      width: 63%;
      font-size: 15px;
      justify-content: end;
      font-weight: bold;
      @media (max-width: 767.98px) {
        justify-content: center;
      }
      // @media (max-width: 449.98px) {
      //   justify-content: center;
      // }
      img {
        margin-left: 10px;

        @media (min-width: 450px) and(max-width: 767.98px) {
          position: absolute;
          right: 20px;
        }
        @media (max-width: 349.98px) {
          margin-left: 5px;
        }
      }
    }
  }
  & .rightSection {
    display: flex;
    align-items: end;
    margin-left: -80px;
    @media only screen and (max-width: 767.98px) {
      display: block;
      text-align: center;
      margin-left: 0px;
    }
    & img {
      width: 173px;

      @media only screen and (min-width: 1024.98px) {
        width: 186px;
      }
      @media only screen and (max-width: 991.98px) {
        margin-left: -115px;
      }
      @media only screen and (max-width: 767.98px) {
        margin-top: -10px;
        width: 198px;
        margin-left: 0;
      }
    }
  }
}
.bottomArrow {
  margin-bottom: -110px;
  width: 11px;
  @media only screen and (max-width: 767.98px) {
    margin-top: -40px;
  }
}
.w-65 {
  width: 65%;
  @media only screen and (max-width: 767.98px) {
    width: 100%;
  }
}
</style>
