<template>
  <div
    class="d-flex flex-column align-items-center question-oval"
    @click="$emit('onCardClick', item?.text)"
  >
    <div
      class="question-oval__oval p-2"
      :class="{ 'question-oval__oval-active': item?.text === home }"
    >
      <img :src="item?.icon" />
    </div>
    <span class="mt-2 question-oval__text"> {{ item?.text }} </span>
  </div>
</template>

<script>
export default {
  name: "AlarmItem",
  props: {
    item: {
      type: Object,
    },
    home: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.question-oval {
  font-family: "Fira Sans", sans-serif;
  cursor: pointer;

  &__oval {
    // width: 113px;
    // height: 113px;
    width: 90px;
    height: 90px;
    position: relative;

    background-color: #ff0033;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;

    position: relative;

    border-radius: 50%;
    &-active {
      // background-color: #ce193d !important;
      background-color: #32b006 !important;
    }
    img {
      width: 50% !important;
      height: 50% !important;
    }
  }
  &__text {
    // font-size: 18px;
    // font-size: 16px;
    font-size: 14px;

    color: #000000;
    font-weight: 400;

    text-align: center;
  }

  @media (max-width: 767.99px) {
    &__text {
      font-size: 12px !important;
    }
    &__oval {
      width: 60px !important;
      height: 60px !important;
    }
  }
}
</style>
