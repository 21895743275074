<template>
  <div id="form">
    <b-row>
      <b-col lg="7" md="6" sm="12" class="px-0 px-md-3">
        <div v-if="step <= 10" class="form bg-secondry text-white mt-md-4 pb-5">
          <div class="col-12 pt-4">
            <div class="container">
              <h3 class="p-0 m-0 text-white">Verisure Inbraakonderzoek</h3>
              <p
                class="p-0 m-0 pr-md-4 text-white form__subHeading pb-3 pb-md-5"
              >
                Beantwoord enkele vragen over jouw kennis. Klik hieronder op het
                antwoord wat jij denkt dat juist is of wat van toepassing is op
                jouw woning.
              </p>
              <Step1
                v-if="step == 1"
                :stepData="stepsData[0]"
                @next="nextStep"
              />
              <Step1Ans v-if="step == 2" @next="nextStep" />
              <Step2
                v-if="step == 3"
                :stepData="stepsData[1]"
                @next="nextStep"
              />
              <Step2Ans v-if="step == 4" @next="nextStep" />
              <Step3
                v-if="step == 5"
                :stepData="stepsData[2]"
                @next="nextStep"
              />
              <Step3Ans v-if="step == 6" @next="nextStep" />
              <Step4
                v-if="step == 7"
                :stepData="stepsData[3]"
                @next="nextStep"
              />
              <Step4Ans v-if="step == 8" @next="nextStep" />
              <Step5
                v-if="step == 9"
                :stepData="stepsData[4]"
                @next="nextStep"
              />
              <Step5Ans v-if="step == 10" @next="nextStep" />
            </div>
          </div>
        </div>
        <!-- Form -->
        <div v-else class="bg-white rounded">
          <Form />
        </div>
      </b-col>
      <b-col lg="5" md="6" sm="12">
        <div class="formImg">
          <img
            v-if="step == 1 || step == 2"
            src="../../assets/form1.jpg"
            width="90%"
            class="rounded formImg__first"
          />
          <img
            v-if="step == 3 || step == 4"
            src="../../assets/form2.jpg"
            width="90%"
            class="rounded formImg__first"
          />
          <img
            v-if="step == 5 || step == 6"
            src="../../assets/form3.jpg"
            width="90%"
            class="rounded formImg__first"
          />
          <img
            v-if="step == 7 || step == 8"
            src="../../assets/form4.jpg"
            width="90%"
            class="rounded formImg__first"
          />
          <img
            v-if="step == 9 || step == 10"
            src="../../assets/form5.jpg"
            width="90%"
            class="rounded formImg__first"
          />
          <img
            v-if="step <= 10"
            src="../../assets/40korting1.png"
            class="rounded formImg__second"
          />
        </div>
        <div v-if="step > 10" class="onFormImg">
          <img
            src="../../assets/40korting1.png"
            class="rounded onFormImg__upper"
          />
          <img
            src="../../assets/form.png"
            class="rounded onFormImg__lower"
            width="90%"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Step1 from "./steps/Step1.vue";
import Step1Ans from "./steps/Step1Ans.vue";
import Step2 from "./steps/Step2.vue";
import Step2Ans from "./steps/Step2Ans.vue";
import Step3 from "./steps/Step3.vue";
import Step3Ans from "./steps/Step3Ans.vue";
import Step4 from "./steps/Step4.vue";
import Step4Ans from "./steps/Step4Ans.vue";
import Step5 from "./steps/Step5.vue";
import Step5Ans from "./steps/Step5Ans.vue";
import Form from "./Form.vue";
export default {
  components: {
    Step1,
    Step1Ans,
    Step2,
    Step2Ans,
    Step3,
    Step3Ans,
    Step4,
    Step4Ans,
    Step5,
    Step5Ans,
    Form,
  },

  data() {
    return {
      step: 1,
      stepsData: [
        {
          title:
            "1. Hoeveel woninginbraken zijn er gemiddeld per jaar in Nederland?",
          options: [
            {
              no: "A",
              text: "25.000 inbraken per jaar",
            },
            {
              no: "B",
              text: "65.000 inbraken per jaar",
            },
            {
              no: "C",
              text: "115.000 inbraken per jaar",
            },
          ],
        },
        {
          title:
            "2. In welke provincie zijn de meeste woninginbraken per jaar volgens het meest recente onderzoek?",
          options: [
            {
              no: "A",
              text: "Noord-Holland",
            },
            {
              no: "B",
              text: "Utrecht",
            },
            {
              no: "C",
              text: "Zuid-Holland",
            },
            {
              no: "D",
              text: "Noord-Brabant",
            },
          ],
        },
        {
          title:
            "3. Hoeveel % van de woningen in Nederland is voorzien van een alarmsysteem?",
          options: [
            {
              no: "A",
              text: "5 tot 10 procent",
            },
            {
              no: "B",
              text: "10 tot 15 procent",
            },
            {
              no: "C",
              text: "15 tot 20 procent",
            },
            {
              no: "D",
              text: "20 tot 25 procent",
            },
          ],
        },
        {
          title: "4. Waar staat uw woning?",
          options: [
            {
              no: "A",
              text: "In het centrum",
            },
            {
              no: "B",
              text: "In een woonwijk ",
            },
            {
              no: "C",
              text: "In een landelijke omgeving ",
            },
          ],
        },
        {
          title: "5. Zijn uw waardevolle spullen zichtbaar van buiten?",
          options: [
            {
              no: "A",
              text: "Ja, deze zijn zichtbaar",
            },
            {
              no: "B",
              text: "Deze zijn deels zichtbaar",
            },
            {
              no: "C",
              text: "Nee, deze zijn niet zichtbaar",
            },
          ],
        },
      ],
    };
  },
  methods: {
    nextStep() {
      this.step++;
    },
  },
};
</script>

<style lang="scss">
.form {
  border-radius: 0.25rem !important;
  &__subHeading {
    font-size: 0.9rem;
    line-height: 1.6;
  }
}
.formImg {
  &__first {
    margin-left: -40px;
    @media only screen and (max-width: 767px) {
      margin-left: 0px;
      width: 100% !important;
      margin-top: -25px;
    }
  }
  &__second {
    margin-top: -40px;
  }
}
.onFormImg {
  &__upper {
    margin-bottom: -40px;
    position: relative;
  }
  &__lower {
    margin-left: -55px;
    @media only screen and (max-width: 767px) {
      margin-left: 0px;
      width: 100% !important;
      margin-top: -25px;
    }
  }
}
.cirkel {
  background-color: #fff;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  color: #ff0032;
  line-height: 30px;
  display: inline-block;
  margin-right: 10px;
}
.option {
  cursor: pointer;
  &:hover {
    & div {
      background-color: rgb(0, 0, 0) !important;
    }
    & p {
      color: rgb(0, 0, 0) !important;
    }
  }
}
.fade-in {
  animation: fadeIn 2.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
