<template>
  <div>
    <b-container class="py-5 text-center bottomSection">
      <h2 class="primary--text text--uppercase fw--700">
        <!-- Gevanceerd alarmsysteem -->
        GEAVANCEERD ALARMSYSTEEM
      </h2>
      <div class="topContent mt-5 text-left">
        <b-row>
          <b-col cols="12" md="6" class="pr-3 pr-md-0">
            <img :src="bottomImg1" width="100%" />
          </b-col>
          <b-col cols="12" md="6" class="p-5">
            <p class="primary--text text--uppercase fw--700">Camera's</p>
            <h1 class="fw--700 fs--md--50">
              Zie 24/7 wie er bij <br />
              jouw woning in de <br />
              buurt komt
            </h1>
            <p class="fs--18 fw-500 my-4">
              Via de Verisure app kan je live meekijken om te zien wie er zich
              in en om jouw woning begeeft.
            </p>
            <p class="fs--18 fw--500 mb-4">
              De camera neemt op in HD-kwaliteit en heeft nachtzicht waardoor in
              het donker ook duidelijke beelden worden gemaakt
            </p>
            <b-button
              :href="`#${link}`"
              pill
              variant="primary"
              class="primary--btn w-75 px-4 py-2 mx-auto d-flex align-items-center justify-content-around postcodeBanner__zipInput--btn"
            >
              <span class="fw--600"> {{ buttonText }} </span>

              <img class="ml-4" :src="btn_upArrow" width="25px"
            /></b-button>
          </b-col>
        </b-row>
        <b-row class="center__row">
          <b-col cols="12" md="6" class="p-5">
            <p class="primary--text text--uppercase fw--700">ZeroVision</p>
            <h1 class="fw--700 fs--md--50">
              Inbrekers kunnen <br />
              niet stelen wat <br />
              ze niet zien
            </h1>
            <p class="fs--18 fw--500 my-4">
              ZeroVision is een rookgordijn dat alleen wordt geactiveerd als er
              inbrekers in jouw woning zijn.
            </p>
            <p class="fs--18 fw--500 mb-5">
              Binnen 1 minuut wordt de ruimte gevuld met een rookgordijn zodat
              ze niks meer kunnen zien.
            </p>
            <b-button
              :href="`#${link}`"
              pill
              variant="primary"
              class="primary--btn w-75 px-4 py-2 mx-auto d-flex align-items-center justify-content-around postcodeBanner__zipInput--btn"
            >
              <span class="fw--600"> {{ buttonText }} </span>

              <img class="ml-4" :src="btn_upArrow" width="25px"
            /></b-button>
          </b-col>
          <b-col cols="12" md="6" class="pl-3 pl-md-0">
            <img :src="bottomImg2" width="100%" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="pr-3 pr-md-0">
            <img :src="bottomImg3" width="100%" />
          </b-col>
          <b-col cols="12" md="6" class="p-5">
            <p class="primary--text text--uppercase fw--700">SOS knop</p>
            <h1 class="fw--700 fs--md--50">
              Schakel hulp in in <br />
              geval van nood
            </h1>
            <p class="fs--18 fw--500 my-4">
              In elke noodsituatie kan je de hulp van onze meldkamer inschakelen
              door de sos knop in te drukken.
            </p>
            <p class="fs--18 fw--500">
              Je krijgt direct contact met onze meldkamer die je helpt in elke
              noodsituatie.
            </p>
            <p class="fs--18 fw--500 mb-5">
              De hulpdiensten worden direct gealarmeerd en richting jouw woning
              gestuurd.
            </p>
            <b-button
              :href="`#${link}`"
              pill
              variant="primary"
              class="primary--btn w-75 px-4 py-2 mx-auto d-flex align-items-center justify-content-around postcodeBanner__zipInput--btn"
            >
              <span class="fw--600"> {{ buttonText }} </span>

              <img class="ml-4" :src="btn_upArrow" width="25px"
            /></b-button>
          </b-col>
        </b-row>
      </div>
      <div class="text-center my-4 bottomStepsSection">
        <img :src="bottoomStepsImg" class="bottomImgs" />
        <h1 class="fw--700 fs--md--40">
          Bescherm jouw woning in <u>3 stappen</u>!
        </h1>
        <img :src="bottomSteps" class="mt-4 mb-5 bottomImgs" />
        <b-button
          :href="`#${link}`"
          pill
          variant="primary"
          class="primary--btn buttomBtn px-4 py-2 mx-auto d-flex align-items-center justify-content-around postcodeBanner__zipInput--btn"
        >
          <span class="fw--600"> {{ buttonText }} </span>

          <img class="ml-4" :src="btn_upArrow" width="25px"
        /></b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
import bottomImg1 from "@/assets/postcoode/bottomImg1.png";
import bottomImg2 from "@/assets/postcoode/bottomImg2.png";
import bottomImg3 from "@/assets/postcoode/bottomImg3.png";
import btn_upArrow from "@/assets/postcoode/btn_upArrow.png";
import bottoomStepsImg from "@/assets/postcoode/bottoomStepsImg.png";
import bottomSteps from "@/assets/postcoode/bottomSteps2new.png";

export default {
  name: "BottomSection",
  props: {
    buttonText: String,
    link: String,
  },
  data() {
    return {
      bottomImg1,
      bottomImg2,
      bottomImg3,
      btn_upArrow,
      bottoomStepsImg,
      bottomSteps,
    };
  },
};
</script>

<style lang="scss" scoped>
.bottomSection {
  font-family: "Fira Sans", sans-serif;
}
.bottomStepsSection {
  & .bottomImgs {
    @media (max-width: 991.98px) {
      width: 100%;
    }
  }
}
.buttomBtn {
  width: 360px;
  @media only screen and (max-width: 767.98px) {
    width: 100%;
  }
}
.topContent {
  background: #f2f2f2;
}
.center__row {
  @media only screen and (max-width: 767.98px) {
    flex-direction: column-reverse;
  }
}
</style>
