<template>
  <div>
    <Header />
    <div class="main-section">
      <FirstQuestion
        v-if="currentQuestion === 1"
        @handleNextClick="handleNextClick"
      />
      <SwitchQuestion
        v-else-if="currentQuestion === 2"
        @handleNextClick="handleNextClick"
        :item="secondQueItems"
        :selected="isValuableVisible"
      />
      <SwitchQuestion
        v-else-if="currentQuestion === 3"
        @handleNextClick="handleNextClick"
        :item="thirdQueItems"
        :selected="entrance"
      />
      <SwitchQuestion
        v-else-if="currentQuestion === 4"
        @handleNextClick="handleNextClick"
        :item="fourthQueItems"
        :selected="indoorCamera"
      />
      <Loader
        v-else-if="currentQuestion === 5"
        @handleNextClick="handleNextClick"
      />
      <Form
        v-else-if="currentQuestion === 6"
        @handleNextClick="handleNextClick"
      />

      <Bedankt :profileId="profileId" v-else-if="currentQuestion === 7" />

      <VerisureAlarm v-if="currentQuestion == 1 || currentQuestion == 6" />
      <Footer
        v-if="currentQuestion == 1 || currentQuestion == 6"
        :currentQuestion="currentQuestion"
      />
    </div>
    <VerisureAlarm
      :currentQuestion="currentQuestion"
      v-if="currentQuestion == 7"
    />
    <Footer
      v-if="currentQuestion !== 1 && currentQuestion !== 6"
      :currentQuestion="currentQuestion"
    />
  </div>
</template>

<script>
import Header from "../components/verisurehuishoudens/Header.vue";
import Footer from "../components/verisurehuishoudens/Footer.vue";

import VerisureAlarm from "../components/verisurehuishoudens/VerisureAlarm.vue";
import FirstQuestion from "../components/verisurehuishoudens/FirstQuestion.vue";
import SwitchQuestion from "../components/verisurehuishoudens/SwitchQuestion.vue";
import Loader from "../components/verisurehuishoudens/Loader.vue";
import Form from "../components/verisurehuishoudens/Form.vue";
import Bedankt from "../components/verisurehuishoudens/Bedankt.vue";

export default {
  name: "verisurehuishoudens",
  components: {
    Header,
    Footer,
    VerisureAlarm,
    FirstQuestion,
    SwitchQuestion,
    Loader,
    Form,
    Bedankt,
  },

  data() {
    return {
      currentQuestion: 1,
      profileId: "",
      home: "",
      secondQueItems: {
        question: "Zijn uw waardevolle spullen zichtbaar van buiten?",
        items: [
          { label: "Ja, die zijn zichtbaar" },
          { label: "Deze zijn deels zichtbaar" },
          { label: "Nee, die zijn niet zichtbaar" },
        ],
      },
      isValuableVisible: "",
      thirdQueItems: {
        question: "Waar bevindt zich de hoofdingang van uw woning?",
        items: [
          { label: "Aan de straat" },
          { label: "Aan de tuin" },
          { label: "Gezamenlijke ingang" },
        ],
      },
      entrance: "",
      fourthQueItems: {
        question: "Zou u een camera binnenshuis willen?",
        items: [
          { label: "Nee, alleen buiten" },
          { label: "Ja, ook binnen" },
          { label: "Weet ik nog niet" },
        ],
      },
      indoorCamera: "",
    };
  },
  methods: {
    handleNextClick(profile) {
      this.currentQuestion = this.currentQuestion + 1;
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (profile) {
        this.profileId = profile;
      }
    },
  },

  created() {
    document.title = "Verisure Huishoudens";
    // <noscript>
    //   <img
    //     height="1"
    //     width="1"
    //     style="display:none"
    //     src="https://www.facebook.com/tr?id=1104555766806350&ev=PageView&noscript=1"
    //   />
    // </noscript>;
    <noscript>
      <img
        height="1"
        width="1"
        style="display:none"
        src="https://www.facebook.com/tr?id=1719035818444105&ev=PageView&noscript=1"
      />
    </noscript>;
  },

  mounted() {
    let useScript = document.createElement("script");

    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),
      fbq("init", "1719035818444105"),
      fbq("track", "PageView")
    );

    document.head.appendChild(useScript);
  },

  watch: {
    currentQuestion(newValue) {
      if (newValue && newValue == 7) {
        let useScript = document.createElement("script");

        useScript.setAttribute(
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod
                ? n.callMethod.apply(n, arguments)
                : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
          ),
          fbq("init", "1719035818444105"),
          fbq("track", "PageView"),
          fbq("track", "Lead")
        );
        document.head.appendChild(useScript);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-section {
  background: #f2f2f2 !important;
  //   height: 95vh;
  padding-top: 40px;
  padding-bottom: 40px;
  height: 659px;

  @media (max-width: 1023.99px) {
    height: 619px;
  }
  @media (max-width: 767.99px) {
    height: 597px;
  }
  @media (max-width: 575.99px) {
    height: 537px;
  }
}
</style>
