<template>
  <div class="home">
    <Banner />
    <HetSection />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from "../components/inbraakonderzoek/Banner.vue";
import HetSection from "../components/inbraakonderzoek/HetSection.vue";
import Footer from "../components/inbraakonderzoek/Footer.vue";

export default {
  name: "inbraakonderzoekHome",
  components: {
    Banner,
    HetSection,
    Footer,
  },
};
</script>

<style>
@font-face {
  font-family: "Gotham Rounded"; /*a name to be used later*/
  src: url(../assets/GothamRoundedMedium.ttf); /*URL to font*/
}
@font-face {
  font-family: "Gotham RoundedLight"; /*a name to be used later*/
  src: url(../assets/GothamRoundedLight.ttf); /*URL to font*/
}
</style>
