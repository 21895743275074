<template>
  <div class="bg bannerInbraak pt-2 pt-md-5">
    <div class="text-center heading">
      <h1 class="font-weight-bold text-white m-0">
        Forse toename <span class="text-secondry">woninginbraken </span> door de
        wintermaanden!
      </h1>
      <h3 class="font-weight-bold text-white">
        Test je kennis en ontvang een 40% korting!
      </h3>
    </div>
    <div class="text-center py-3 py-md-0 bg-white d-block d-md-none">
      <img src="../../assets/logo1.png" width="30%" />
    </div>
    <b-container>
      <!-- Form Section to be added -->
      <FormSection />
    </b-container>
    <img
      class="mt-0 mt-md-5"
      src="../../assets/banner tijdelijk aanbod1.png"
      width="100%"
    />
  </div>
</template>

<script>
import FormSection from "./FormSection.vue";
export default { components: { FormSection } };
</script>

<style lang="scss">
.heading {
  & h1 {
    @media only screen and (max-width: 767px) {
      font-size: 1.4rem !important;
    }
  }
}
h3 {
  @media only screen and (max-width: 767px) {
    font-size: 1.2rem !important;
  }
}
.bg {
  background-color: #222 !important;
}
.text-secondry {
  color: #ff0032 !important;
}
.bg-secondry {
  background-color: #ff0032 !important;
}
h1 {
  font-size: 2.6rem !important;
}
.bannerInbraak {
  font-family: "Gotham Rounded", sans-serif;
}
</style>
