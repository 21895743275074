<template>
  <b-container class="secondBanner py-5">
    <b-container class="bg-white text-center">
      <p class="fw--700">
        Jouw postcode wordt nu gecontroleerd, <br />
        een moment geduld...
      </p>
      <b-spinner
        variant="danger"
        class="mx-auto mt-4"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </b-container>
  </b-container>
</template>

<script>
export default {
  name: "SecondBanner",
};
</script>

<style lang="scss" scoped>
.secondBanner {
  font-family: "Fira Sans", sans-serif !important;
  height: 590px;
  & div {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    border-radius: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}
</style>
