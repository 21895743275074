import Vue from "vue";
import VueRouter from "vue-router";
import verisureveiligHome from "../views/verisureveiligHome.vue";
import verisureveiligBedankt from "../views/verisureveiligBedankt.vue";
import Inbraak from "../views/inbraakonderzoekHome.vue";
import InbraakBedankt from "../views/inbraakonderzoekBedankt.vue";
import VideodeurbelveiligHome from "../views/VideodeurbelveiligHome.vue";
import VideodeurbelveiligBedankt from "../views/VideodeurbelveiligBedankt.vue";
import videodeurbelinbraakonderzoekHome from "../views/videodeurbelinbraakonderzoekHome.vue";
import videodeurbelinbraakonderzoekBedankt from "../views/videodeurbelinbraakonderzoekBedankt.vue";
import Postcodechecker from "@/views/Postcodechecker.vue";
import Postcodecheckerv2 from "@/views/PostcodecheckerV2.vue";
import PostcodecheckerV3 from "@/views/PostcodecheckerV3.vue";
import PostcodecheckerV4 from "@/views/PostcodecheckerV4.vue";
import PostcodecheckerV5 from "@/views/PostcodecheckerV5.vue";
import verisurehuishoudens from "../views/verisurehuishoudens.vue";
import Postcodecheckerhh from "@/views/Postcodecheckerhh.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "verisureveiligHome",
  //   component: verisureveiligHome,
  //   redirect: "/verisureveilig",
  // },
  // {
  //   path: "/verisureveilig",
  //   name: "verisureveiligHome",
  //   component: verisureveiligHome,
  // },
  // {
  //   path: "/bedankt",
  //   name: "verisureveiligBedankt",
  //   component: verisureveiligBedankt,
  //   redirect: "/verisureveilig/bedankt",
  // },
  // {
  //   path: "/verisureveilig/bedankt",
  //   name: "verisureveiligBedankt",
  //   component: verisureveiligBedankt,
  // },
  // {
  // 	path: '/inbraakonderzoek',
  // 	name: 'Inbraak',
  // 	component: Inbraak,
  // },
  // {
  // 	path: '/inbraakonderzoek/bedankt',
  // 	name: 'InbraakBedankt',
  // 	component: InbraakBedankt,
  // },

  // VideodeurbelveiligBedankt
  // {
  //   path: "/videodeurbelveilig",
  //   name: "VideodeurbelveiligHome",
  //   component: VideodeurbelveiligHome,
  // },
  // {
  //   path: "/videodeurbelveilig/bedankt",
  //   name: "VideodeurbelveiligBedankt",
  //   component: VideodeurbelveiligBedankt,
  // },
  // videodeurbelinbraakonderzoek
  // {
  //   path: "/videodeurbelinbraakonderzoek",
  //   name: "videodeurbelinbraakonderzoekHome",
  //   component: videodeurbelinbraakonderzoekHome,
  // },
  // {
  //   path: "/videodeurbelinbraakonderzoek/bedankt",
  //   name: "videodeurbelinbraakonderzoekBedankt",
  //   component: videodeurbelinbraakonderzoekBedankt,
  // },
  // postcodechecker
  // {
  //   path: "/postcodechecker",
  //   name: "postcodechecker",
  //   component: Postcodechecker,
  // },
  // // postcodecheckerv2
  // {
  //   path: "/postcodecheckerv2",
  //   name: "postcodecheckerv2",
  //   component: Postcodecheckerv2,
  // },
  // // postcodecheckerv3
  // {
  //   path: "/postcodecheckerv3",
  //   name: "postcodecheckerv3",
  //   component: PostcodecheckerV3,
  // },
  // // postcodecheckerv4
  // {
  //   path: "/postcodecheckerv4",
  //   name: "postcodecheckerv4",
  //   component: PostcodecheckerV4,
  // },
  // // postcodecheckerv5
  // {
  //   path: "/postcodecheckerv5",
  //   name: "postcodecheckerv5",
  //   component: PostcodecheckerV5,
  // },
  // //verisurehuishoudens
  // {
  //   path: "/verisurehuishoudens",
  //   name: "verisurehuishoudens",
  //   component: verisurehuishoudens,
  // },
  //postcodecheckerhh
  {
    path: "/",
    name: "postcodecheckerhh",
    component: Postcodecheckerhh,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
